/* Estilos para el botón de WhatsApp */
.btn-wsp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background: #25D366; /* Color de fondo típico de WhatsApp */
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 100;
  text-decoration: none;
}

.btn-wsp:hover {
  background: #128C7E; /* Color más oscuro al pasar el mouse */
}
