/* Estilos para los sliders */
.clients-slider-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.mobile-clients-slider-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

/* Estilos para el slider en pantallas pequeñas */
.slick-slide img {
  display: block;
  margin: 0 auto;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  color: white;
}

.slick-prev, .slick-next {
  z-index: 1;
}

/* Ajustar tamaño de los logos en los sliders */
.logo-img {
  max-height: 60px; /* Ajusta el tamaño máximo según tu preferencia */
  width: auto;
  object-fit: contain; /* Asegúrate de que las imágenes mantengan su proporción */
  margin: 0 auto;
}

/* Opcional: estilos adicionales si es necesario */
@media (max-width: 640px) {
  .logo-img {
    max-height: 50px; /* Tamaño reducido en pantallas muy pequeñas */
    width: auto;
  }
}