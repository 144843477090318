@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* src/components/Loader.css */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-video {
  max-width: 260px;
  background-color: transparent;
}

.slick-slide > div {
  margin: 0 10px;
}

.card {
  width: 400px;
  height: 500px;
  border-radius: 20px;
  margin: 50px auto;
  background: #ff1e46;
  overflow: hidden;
}

.card-back {
  background: #ff1e46;
}

.card-bottom {
  display: flex;
  height: 110px; /* Ajusta el tamaño según tu diseño */
}

.card-bottom .half {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media (max-width: 640px) {
  .card-bottom {
    height: 130px; /* También puedes reducir un poco la altura */
  }
}

@media (max-width: 640px) {
  .card {
    width: 280px; /* Cambia el ancho para que sea más delgada en móviles */
    height: 400px; /* También puedes reducir un poco la altura */
    margin: 20px auto; /* Ajusta el margen */
  }

  .card-back {
    background: #ff1e46;
  }
}

.contact-form-glow {
  box-shadow: 0 0 15px rgba(255, 30, 70, 0.8),
    /* Primera capa de luz azul */ 0 0 30px rgba(255, 30, 70, 0.7),
    /* Segunda capa de luz más tenue */ 0 0 45px rgba(255, 30, 70, 0.6); /* Capa exterior más suave */
  border: 2px solid rgba(255, 30, 70, 1); /* Borde con un tono azul claro */
  transition: box-shadow 0.3s ease-in-out;
}

/*About us rectangle*/
/* Estilos generales */
.custom-rectangle {
  width: 800px; /* Ancho en pantallas grandes */
  height: 150px; /* Altura en pantallas grandes */
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Fondo blanco semi-transparente */
  border: 2px dashed rgba(255, 0, 0, 0.8); /* Borde rojo discontinuo */
  border-radius: 20px; /* Esquinas redondeadas */
  padding: 20px; /* Espacio interior */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ajustes para pantallas medianas (tablets) */
@media (max-width: 1024px) {
  .custom-rectangle {
    width: 600px; /* Ancho en pantallas medianas */
    height: 130px; /* Altura en pantallas medianas */
  }
}

/* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .custom-rectangle {
    width: 90%; /* Usamos un porcentaje para que se ajuste mejor */
    height: 120px; /* Altura en pantallas pequeñas */
    padding: 15px; /* Disminuir el padding en pantallas pequeñas */
  }
}

/* Ajustes para pantallas muy pequeñas (teléfonos antiguos) */
@media (max-width: 480px) {
  .custom-rectangle {
    width: 85%; /* Ancho reducido en pantallas muy pequeñas */
    height: 120px; /* Altura reducida en pantallas muy pequeñas */
    padding: 10px; /* Espacio interior más pequeño */
  }
}

@font-face {
  font-family: "Nebulica";
  src: url("./fonts/Nebulica-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nebulica";
  src: url("./fonts/Nebulica-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nebulica";
  src: url("./fonts/Nebulica-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nebulica";
  src: url("./fonts/Nebulica-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nebulica";
  src: url("./fonts/Nebulica-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@media only screen and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
  .landscape-mobile {
    height: 200vh;
  }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .landscape-desktop {
    height: 120vh;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .contact-form-container {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

.border-highlight {
  box-shadow: 0 0 10px rgba(255, 30, 70, 0.8), 0 0 20px rgba(255, 30, 70, 0.7),
    0 0 30px rgba(255, 30, 70, 0.6);
  border: 2px solid rgba(255, 30, 70, 1);
  transition: box-shadow 0.3s ease-in-out;
}

/* Animate Roll Open */
@keyframes roll-open {
  0% {
    transform: scaleY(0); /* Inicia como comprimido verticalmente */
    opacity: 0; /* Inicia invisible */
  }
  100% {
    transform: scaleY(1); /* Desplegado completamente */
    opacity: 1; /* Totalmente visible */
  }
}

.animate-roll-open {
  animation: roll-open 1s ease-out forwards;
  transform-origin: top; /* El efecto se originará desde la parte superior */
}

.scrollable-container {
  overflow-y: auto;
  max-height: 90vh; /* Ajusta según sea necesario */
  scrollbar-width: none; /* Firefox */
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari y Opera */
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-out forwards;
}
